<template>
  <div class="address-mangement-container">
    <div class="address__wrapper">
      <template v-if="theme === 'page'">
        <template v-if="list.length > 0">
          <div
            class="address__item"
            v-for="address in list"
            :key="address.id"
            :class="{
              active: address.actived
            }"
            @click.stop="onClick"
            :data-id="address.id"
          >
            <div class="item__wrapper">
              <div class="item__content">
                <div class="content__info-row">
                  <p>收货人：</p>
                  <p>{{ address.consignee }}</p>
                </div>
                <div class="content__info-row">
                  <p>地址：</p>
                  <p>
                    {{ address.province }} {{ address.city }} {{ address.area }}
                    {{ address.detail }}
                  </p>
                </div>
                <div class="content__info-row">
                  <p>手机：</p>
                  <p>{{ address.consignee_tel }}</p>
                </div>
              </div>
              <div class="item__action-bts">
                <div
                  class="bt__item"
                  @click.stop="setDefault"
                  v-if="Number(address.is_default) === 0"
                  :data-id="address.id"
                >
                  设置为默认地址
                </div>
                <div
                  class="bt__item"
                  @click.stop="onEdit"
                  :data-id="address.id"
                >
                  修改
                </div>
                <div class="bt__item" @click.stop="onDel" :data-id="address.id">
                  删除
                </div>
              </div>
            </div>
          </div>
        </template>
        <div class="address__item --add-bt" @click="addAddress">
          <div class="item__wrapper"></div>
        </div>
      </template>
      <template v-else-if="theme === 'component' && defaultAddress">
        <router-link
          class="address__item"
          tag="div"
          :to="{
            path: '/address'
          }"
        >
          <div class="item__wrapper">
            <div class="item__content">
              <div class="content__info-row">
                <p>收货人：</p>
                <p>{{ defaultAddress.consignee }}</p>
              </div>
              <div class="content__info-row">
                <p>地址：</p>
                <p>
                  {{ defaultAddress.province }} {{ defaultAddress.city }}
                  {{ defaultAddress.area }}
                  {{ defaultAddress.detail }}
                </p>
              </div>
              <div class="content__info-row">
                <p>手机：</p>
                <p>{{ defaultAddress.consignee_tel }}</p>
              </div>
            </div>
            <div class="item__action-bts">
              <div
                class="bt__item"
                @click.stop="onEdit"
                :data-id="defaultAddress.id"
              >
                修改
              </div>
              <div
                class="bt__item"
                @click.stop="onDel"
                :data-id="defaultAddress.id"
              >
                删除
              </div>
            </div>
          </div>
        </router-link>
      </template>
    </div>
    <edit-form v-model="showEdit" :update-id="updateId"></edit-form>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import editForm from "./_edit";
export default {
  name: "c_address-mangement",
  components: {
    editForm
  },
  props: {
    theme: {
      type: String,
      default: "page"
    }
  },
  data() {
    return {
      list: [],
      showEdit: false,
      updateId: 0
    };
  },
  computed: {
    ...mapState("shop", ["addresses"]),
    defaultAddress() {
      if (this.addresses.length > 0) {
        return this.addresses.find(item => item.is_default === 1);
      }
      return null;
    }
  },
  watch: {
    $route: {
      handler() {
        this.addresslist();
      },
      immediate: true
    },
    addresses: {
      handler(val) {
        const that = this;
        const lists = val.map(item => {
          const temp = item;
          temp.actived = Number(temp.is_default) === 1;
          if (Number(temp.is_default) === 1) {
            this.$emit("checked", temp.id);
          }
          return temp;
        });
        const index = lists.findIndex(item => item.is_default === 1);
        if (index > 0) {
          lists.unshift(lists.splice(index, 1)[0]);
        }
        that.list = lists;
      },
      immediate: true
    }
  },
  mounted() {
    console.log(this.theme);
  },
  methods: {
    ...mapActions({
      addresslist: "shop/getAddress"
    }),
    ...mapMutations("shop", ["DEL_ADDRESS", "SET_DEFAULT"]),
    addAddress() {
      this.updateId = 0;
      this.showEdit = true;
    },
    setDefault(e) {
      const that = this;
      const id = e.currentTarget.dataset.id;
      that
        .$confirm("是否确认设置为默认地址？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
        .then(() => {
          that.$request
            .get(`/api/user/address/set-default/?id=${id}`)
            .then(res => {
              that.SET_DEFAULT(res.data);
            });
          console.log(id);
        })
        .catch(() => {
          console.log("取消");
        });
    },
    onEdit(e) {
      const id = e.currentTarget.dataset.id;
      this.updateId = Number(id);
      this.showEdit = true;
    },
    onDel(e) {
      const that = this;
      const id = e.currentTarget.dataset.id;
      that
        .$confirm("是否确认删除此地址？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
        .then(() => {
          that.$request.del(`/api/user/address?id=${id}`);
          that.DEL_ADDRESS(id);
        })
        .catch(() => {
          console.log("取消");
        });
    },
    onClick(e) {
      const id = e.currentTarget.dataset.id;
      this.list = this.list.map(item => {
        const temp = item;
        temp.actived = Number(item.id) === Number(id);
        return temp;
      });
      this.$emit("checked", id);
    }
  }
};
</script>

<style></style>
