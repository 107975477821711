<template>
  <div class="edit-address-container" v-if="innerIsShow">
    <div class="bg"></div>
    <div class="edit-form-container">
      <div class="form__title">
        <span>address</span> |
        <span>{{ updateId === 0 ? "新增收件人信息" : "修改收件人信息" }}</span>
      </div>
      <div class="form__close" @click="onClose"></div>
      <el-form
        class="address-edit-form"
        inline
        label-width="80px"
        ref="addressForm"
        :model="form"
        :rules="rules"
      >
        <el-form-item class="form__item" label="收货人：" prop="consignee">
          <el-input v-model="form.consignee"></el-input>
        </el-form-item>
        <el-form-item class="form__item --inline" label="所在地区：">
          <el-form-item class="form__item" prop="province">
            <el-select
              placeholder="省"
              @change="provinceOnChange"
              v-model="form.province"
            >
              <template v-if="pData">
                <el-option
                  v-for="p in pData"
                  :key="p.code"
                  :value="p.code"
                  :label="p.name"
                ></el-option>
              </template>
            </el-select>
          </el-form-item>
          <el-form-item class="form__item" prop="city">
            <el-select
              @change="cityOnChange"
              placeholder="市"
              v-model="form.city"
            >
              <template v-if="cData">
                <el-option
                  v-for="c in cData"
                  :key="c.code"
                  :value="c.code"
                  :label="c.name"
                ></el-option>
              </template>
            </el-select>
          </el-form-item>
          <el-form-item class="form__item" prop="area">
            <el-select placeholder="区/县" v-model="form.area">
              <template v-if="aData">
                <el-option
                  v-for="a in aData"
                  :key="a.code"
                  :value="a.code"
                  :label="a.name"
                ></el-option>
              </template>
            </el-select>
          </el-form-item>
        </el-form-item>
        <el-form-item class="form__item" label="详细地址：" prop="detail">
          <el-input v-model="form.detail"></el-input>
        </el-form-item>
        <el-form-item
          class="form__item"
          label="手机号码："
          prop="consignee_tel"
        >
          <el-input v-model="form.consignee_tel"></el-input>
        </el-form-item>
        <el-form-item class="submitbt form__item">
          <el-button @click="onSubmit" :loading="requesting"
            >保存信息</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import cityData from "@/libs/address.json";
import { mapMutations, mapState } from "vuex";
export default {
  name: "c_address-edit-form",
  model: {
    prop: "isShow"
  },
  props: {
    isShow: Boolean,
    updateId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      cData: null, // 城市列表
      aData: null, // 区列表,
      form: {
        province: "",
        city: "",
        area: "",
        detail: "",
        consignee: "",
        consignee_tel: "",
        is_default: 0
      },
      rules: {
        province: [
          {
            required: true,
            message: "请选择省",
            trigger: "change"
          }
        ],
        city: [
          {
            required: true,
            message: "请选择市",
            trigger: "change"
          }
        ],
        area: [
          {
            required: true,
            message: "请选择区",
            trigger: "change"
          }
        ],
        detail: [
          {
            required: true,
            message: "请填写详细地址",
            trigger: "blur"
          }
        ],
        consignee: [
          {
            required: true,
            message: "请填写联系人",
            trigger: "blur"
          }
        ],
        consignee_tel: [
          {
            required: true,
            message: "请填写联系方式",
            trigger: "blur"
          }
        ]
      },
      requesting: false,
      innerIsShow: this.isShow,
      updateData: null
    };
  },
  watch: {
    isShow(val) {
      this.innerIsShow = val;
    },
    updateId(val) {
      if (val > 0) {
        this.updateData = this.addresses.find(item => item.id === val);
        const p = cityData.find(item => item.name === this.updateData.province);
        this.cData = p.children.map(item => ({
          code: item.code,
          name: item.name
        }));
        const c = p.children.find(item => item.name === this.updateData.city);
        this.aData = c.children.map(item => ({
          code: item.code,
          name: item.name
        }));
        const a = c.children.find(item => item.name === this.updateData.area);
        this.form = {
          province: p.code,
          city: c.code,
          area: a.code,
          detail: this.updateData.detail,
          consignee: this.updateData.consignee,
          consignee_tel: this.updateData.consignee_tel,
          is_default: this.updateData.is_default
        };
      } else if (val === 0) {
        this.form = {
          province: "",
          city: "",
          area: "",
          detail: "",
          consignee: "",
          consignee_tel: "",
          is_default: 0
        };
      }
    }
  },
  computed: {
    ...mapState("shop", ["addresses"]),
    pData() {
      // 省份列表
      return cityData.map(item => ({
        code: item.code,
        name: item.name
      }));
    }
  },
  methods: {
    ...mapMutations("shop", ["ADD_ADDRESS", "UPDATE_ADDRESS"]),
    onClose() {
      this.innerIsShow = false;
      this.$emit("input", false);
    },
    provinceOnChange(code) {
      this.form.city = null;
      this.form.area = null;
      const tempProvince = cityData.find(item => item.code === code);
      this.cData = tempProvince.children.map(item => ({
        code: item.code,
        name: item.name
      }));
    },
    cityOnChange(code) {
      this.form.area = null;
      this.aData = cityData
        .find(item => item.code === this.form.province)
        .children.find(item => item.code === code)
        .children.map(item => ({
          code: item.code,
          name: item.name
        }));
    },
    onSubmit() {
      const that = this;
      that.$refs.addressForm.validate(valid => {
        if (valid && !that.requesting) {
          that.requesting = true;
          const p = cityData.find(
            item => Number(item.code) === Number(that.form.province)
          );
          const c = p.children.find(
            item => Number(item.code) === Number(that.form.city)
          );
          const a = c.children.find(
            item => Number(item.code) === Number(that.form.area)
          );
          that.form.province = p.name;
          that.form.city = c.name;
          that.form.area = a.name;
          if (that.updateId > 0) {
            that.form.id = that.updateId;
          }
          that.$request
            .post("/api/user/address", that.form)
            .then(res => {
              if (that.updateId > 0) {
                that.UPDATE_ADDRESS(res.data);
              } else {
                that.ADD_ADDRESS(res.data);
              }

              that.onClose();
            })
            .catch(err => {
              console.error(err);
            })
            .finally(() => {
              that.requesting = false;
            });
        }
      });
    }
  }
};
</script>

<style></style>
